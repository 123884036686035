#choqi-header {
    background-color: #13AE9C;
    height: 80px;
}

.navbar {
    min-height: 64px;
}

.navbar-toggle {
    display: none;
}

.navbar-static-top.navbar-inverse {
    background-color: #13AE9C;
    border-color: transparent;
    -webkit-box-shadow: 0 3px 5px rgb(15 138 124 / 30%);
    -moz-box-shadow: 0 3px 5px rgba(15, 138, 124, 0.3);
    box-shadow: 0 3px 5px rgb(15 138 124 / 30%);
}

.nav>.dropdown {
    padding: 0px;
}

.dropdown a {
    color: white;
    text-decoration: none;
}

.btn .dropdown-toggle:hover {
    border: 0px;
}
#btn-dropdown {
    border: 0px;
    background-color: transparent;
    color: #fff !important; 
    font-size: 14px !important;
}

#btn-dropdown:focus {
    border: 0px !important;
    box-shadow: none;
    color:#00c !important;
    background-color: #fff;
    outline: 0ch;
}

.dropdown-menu {
    position: absolute !important;
    /* top: 80% !important; */
    left: -103% !important;
    margin-top: 1.75rem !important;
    background-color: #13AE9C !important;
}

.dropdown-menu > li {
    text-align: center;
}
.dropdown-menu > li > span {
    color: white;
    text-decoration: none;
    text-align: center;
}
.dropdown-menu > li > span:hover {
    cursor: pointer;
}

.dropdown-menu .divider {
    height: 1px;
    margin: 9.5px 0;
    overflow: hidden;
    background-color: #e5e5e5;
    width: 100%;
}

@media (max-width: 1199px) {
    #navbar {
        all: unset !important;
    }
}

@media (max-width: 767px) {
    .dropdown-menu { 
        margin-top: 1.76rem !important;
    }
    #dropdown-menu-header .dropdown-menu-text {
        margin-right: 10px !important;
        font-size: 12px !important;
    }
}

@media(max-width: 578px) {
    #dropdown-menu-header .dropdown-menu-text {
        margin-right: 0px !important;
    }
}

@media (max-width: 575px) {
    #root {
        overflow: hidden !important;
    }
}

@media (max-width: 569px) {
    #navbar {
        flex-grow: 1 !important;
        all: initial !important;
        width: 50% !important;
    }
    .dropdown-menu {
        left: -110% !important;
        margin-top: 1.75rem !important;
    }
}

@media (max-width: 546px) {
    #navbar {
        flex-grow: 1 !important;
        all: initial !important;
        width: 50% !important;
    }
    
}

@media(max-width: 544px) {
    .dropdown-menu {
        left: -110% !important;
        margin-top: 1.7rem !important;
    }
}
@media (max-width: 484px) {
    .dropdown-menu {
        margin-top: 1.7rem !important;
    }

    .dropdown-menu > li {
        font-size: 10px !important;
    }
}

@media (max-width: 472px) {
    #dropdown-menu-header .dropdown-menu-text {
        font-size: 11px !important;
    }

    .dropdown-menu {
        margin-top: 1.75rem !important;
    }
}

@media (max-width: 462px) {
    .navbar-header {
        width: 50% !important;
    }
    #navbar {
        width: 50% !important;
    }
    .dropdown-menu {
        margin-top: 1.8rem !important;
    }

    #dropdown-menu-header .dropdown-menu-text {
        font-size: 11px !important;
    }
    .dropdown-menu {
        left: -104% !important;
    }
}

@media (max-width: 462px) {
    .dropdown-menu {
        margin-top: 1.8rem !important;
    }
}

@media (max-width: 450px) {
    .dropdown-menu {
        margin-top: 1.55rem !important;
    }
}

@media (max-width: 406px) {
    .dropdown-menu {
        margin-top: 1.75rem !important;
    }

    #dropdown-menu-header .dropdown-menu-text {
        font-size: 10px !important;
    }
}

.header-display-agxStoreName-fullName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
}

.tooltipText {
    visibility: hidden;
    background-color: #107c70;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 44px;
    left: 0px;
}

.header-display-agxStoreName-fullName:hover .tooltipText {
    visibility: visible;
}