body {
  margin: 0;
  font-family: 'Yu Gothic UI',Segoe\000020UI,Tahoma,Arial !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
  font-weight: bold !important;
}

.tab-checkbox label {
  font-weight: normal !important;
}